.tableWrapper .tabulator-row .tabulator-cell {
  padding: 14px 5px;
}

.pageTitleButtonWrp {
  display: flex;
  align-items: center;
}

.pageTitleButtonWrp .titleButtonCnt {
  flex-grow: 1;
  text-align: right;
}

.pageTitleButtonWrp .titleButtonCnt button {
  margin-left: 20px;
}

.txtInputQYT input {
  text-align: right;
}

.tabulator-editing input {
  outline: 0;
  text-align: right !important;
}

.tabulator .tabulator-header .tabulator-col .tabulator-col-content .tabulator-col-title {
  white-space: normal;
}

.tabluator-column-editable {
  background-color: #DEEDD6;
  /*thistle, #E5CDE2, DEEDD6, FEE2C8, A9C4E5 */
}

.text-field-editable input {
  outline: 0;
  text-align: left !important;
}


.tabluator-column-lightgreen {
  background-color: #e4f2e6 !important;
  /*thistle, #E5CDE2, DEEDD6, FEE2C8, A9C4E5 */
}

.tabluator-column-lightyellow {
  background-color: #f8f2e5 !important;
  /*thistle, #E5CDE2, DEEDD6, FEE2C8, A9C4E5 */
}

/* .tabulator .tabulator-header .tabulator-col[tabulator-field="AMC"]{
  background-color: red !important;
} */

/*
.modalPopupDelete{
  background-color: rgb(20, 160, 206)
}
*/