
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&family=Raleway:wght@400;500;600;700&display=swap');

:root {
  --primary-font-family: 'Open Sans', sans-serif;
  --secondary-font-family: 'Raleway', sans-serif !important;
}

body {
	font-family: var(--primary-font-family);
}

.MuiTypography-root,
.MuiInputBase-input {
  font-family: var(--primary-font-family) !important;
}

.MuiFormLabel-root {
  font-family: var(--secondary-font-family) !important;
}
 
.highcharts-title,
.highcharts-legend-item, 
.highcharts-axis-labels {
  font-family: var(--secondary-font-family) !important;
}

.highcharts-root {
  font-family: var(--primary-font-family) !important;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video,
button,
input,
optgroup,
select,
textarea {
  font-family: var(--primary-font-family);
  letter-spacing: 0 !important;
}

.loader-div {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(10px);
}

/* Login Page Css */

.password-eye {
  padding: 15px 15px 15px 10px !important;
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
  margin: auto;
  cursor: pointer;
}
.link {
  cursor: pointer;
}

/* Signup Page Css */

.signup-top-padding {
	padding: 20px 5px !important;
}

/* dashboard Page Css */

.dashboard-pannel {
  background-color: #e9ecef;
  border-radius: 10px;
  min-height: 60vh;
  padding: 5px 10px 10px 10px;
  margin-top: 65px;
}

.custom-breadcrum ol {
  margin: 0 3rem 1rem !important;
  background-color: transparent !important;
  justify-content: flex-end;
}

.charts-sec {
  margin-top: 25px;
}

.margin_auto {
  margin: auto;
}

@media only screen and (max-width: 1220px) {
  .custom-breadcrum ol {
    margin: 0 0 1rem !important;
  }
}

/* User Page */
.pad-2 {
  padding: 2rem;
}

/* Product Page  */
.uniqueName th,
td {
  border: 1px solid rgba(224, 224, 224, 1);
}

.product-head {
  font-size: 1.825em;
}

.justify-product {
  align-items: center;
  justify-content: space-between;
}

/* Modal Css */
.btn-row-modal {
  justify-content: flex-end;
  margin-top: 15px;
}

/* common css*/
.uidash {
  background-color: whitesmoke;
  border-radius: 10px;
}
.full-width {
  width: 100%;
}
.text-end {
  text-align: end;
}

.cursor-pointer {
  cursor: pointer !important;
}

.width_80 {
  width: 80%;
}
.reset-modal-btn {
  justify-content: center !important;
  margin: 10px;
}
.nice_date_input_custom {
  padding: 5px 10px;
  border: 1px solid lightgrey;
}
.date-range {
  color: #111;
}
.date-range span {
  margin-left: 5px;
  margin-right: 5px;
}

.nice-dates-navigation,
.nice-dates-day {
  color: #111;
}
.nice-dates-popover {
  box-shadow: none;
  border: 1px solid #ddd;
  border-radius: 2px;
  max-width: 400px !important;
  transition: none;
}
.align-center {
  align-items: center !important;
}
.add-receive-btn {
  display: flex;
  justify-content: flex-end;
  align-content: center;
  align-items: center;
}
.receive-head {
  color: #111;
}
.justify-flex-end {
  display: flex;
  justify-content: flex-end;
}
.width-100 {
  width: 100%;
}
.h-24 {
  height: 24rem;
}

.example-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.grid-wrapper {
  flex: 1 1 0px;
}

#myGrid {
  height: 100%;
  width: 100%;
}
.pt-6 {
  padding-top: 6rem;
}

.sw_form_control {
  width: 100% !important;
}
.sw_form_control label.MuiInputLabel-formControl {
  font-size: 24px !important;
  font-weight: 700 !important;
  color: #111 !important;
}
.sw_form_control .MuiInput-formControl {
  margin-top: 24px !important;
}
.sw_radiogroup .MuiFormControlLabel-root .MuiIconButton-colorSecondary {
  padding: 0px 5px 0px 9px !important;
}
.sw_Checkboxgroup .MuiFormControlLabel-root .MuiIconButton-colorPrimary {
  padding: 0px 5px 0px 9px !important;
}
.sw_radiogroup .MuiFormControlLabel-label,
.sw_Checkboxgroup .MuiFormControlLabel-label,
.MuiFormControlLabel-label {
  font-size: 13px !important;
}
.sw_radiogroup label {
  margin-bottom: 2px !important;
}
.sw_radiogroup legend.MuiFormLabel-root {
  margin-bottom: 3px !important;
}
.marginTop5 {
  margin-top: 5px !important;
}
.marginTop10 {
  margin-top: 10px !important;
}
.marginTop15 {
  margin-top: 15px !important;
}
.marginTop20 {
  margin-top: 20px !important;
}
.marginBottom10 {
  margin-bottom: 10px !important;
}
.marginBottom15 {
  margin-bottom: 15px !important;
}
.marginBottom20 {
  margin-bottom: 20px !important;
}
.paddingLR {
  padding-left: 25px !important;
  padding-right: 25px !important;
}

.sw_card .MuiGrid-spacing-xs-3 > .MuiGrid-item {
  padding: 5px 12px !important;
}
.sw_card .MuiGrid-spacing-xs-3 {
  margin-top: 10px !important;
}
.sw_card .MuiCardHeader-root {
  border-bottom: 1px solid #dedede;
  padding: 10px 14px !important;
}
.sw_card .MuiCardHeader-root .MuiCardHeader-title {
  font-size: 15px !important;
}
.sw_overlay_text {
  position: relative;
}
.sw_overlay_text .sw_pack_size {
  position: absolute;
  right: 0px;
  bottom: 5px;
  font-size: 13px;
}
.sw_overlay_text .MuiFormControl-fullWidth {
  width: 62%;
}
.marginTop20 {
  margin-top: 20px !important;
}
.sw_fullWidth_hidden {
  width: 100% !important;
  overflow: hidden;
}
.sw_text_label {
  font-size: 20px !important;
}
.navbar .navbar-brand {
  font-size: 24px !important;
  font-weight: bold !important;
  padding-bottom: 0 !important;
  text-transform: initial !important;
}
.sw_sub_title {
  width: 100%;
  float: left;
  color: #fff;
  font-family: var(--secondary-font-family);
}
.brand .h1-seo {
  text-transform: initial !important;
}
.MuiTableCell-root {
  padding: 4px 5px !important;
  font-size: 13px !important;
}
.MuiIconButton-root {
  padding: 3px !important;
}

.classDispenserLevel {
  border: 1px solid #ccc;
  font-size: 95px;
  width: 100px;
  height: 100px;
  display: inline-block;
  text-align: center;
  line-height: 100px;
}

.MuiInputBase-input {
  font-size: 13px !important;
}
.MuiMenuItem-root {
  font-size: 13px !important;
}
.makeStyles-tableContainer-3 {
  padding: 1rem 2rem !important;
}
.MuiCardContent-root {
  padding: 10px !important;
}
.sw_list {
}
.sw_list li {
  padding: 0px;
}
.sw_list li .MuiListItemText-root {
  margin-top: 0px !important;
}
.sw_list li .MuiTypography-root {
  font-size: 13px !important;
}
.btn,
.navbar .navbar-nav > a.btn {
  font-size: 13px !important;
}
.sw_page_heading {
  width: 100%;
  display: inline-block;
}
.sw_heading_title {
  float: left;
}
.sw_heading_title,
.sw_heading_title_inner {
  font-size: 16px;
  font-weight: 500;
  font-family: var(--secondary-font-family);
  text-transform: uppercase;
}
.sw_btn_control {
}
.sw_btn_control .btn {
  padding: 5px 10px;
}
.MuiToolbar-regular {
  min-height: 35px !important;
}
.stock {
  background-color: #339933;
  color: #fff !important;
}
.overstocked {
  background-color: #002060;
  color: #fff !important;
}
.understocked {
  background-color: #cdad23;
  color: #fff !important;
}
.stockedout {
  background-color: #c00000;
  color: #fff !important;
}
.sw_filter_card {
  margin-bottom: 10px;
}
.sw_filter_card .sw_filterCardContent {
  padding: 10px !important;
}
.sw_item_list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.sw_item_list li {
  float: left;
  margin-right: 20px;
  margin-bottom: 2px;
  margin-top: 2px;
  font-size: 13px;
}
.stat-cell {
  overflow: hidden;
  padding: 8px;
  position: relative;
  background: #a1a194;
  width: 100%;
  color: #fff;
}
.stat-cell .bg-icon {
  bottom: 0;
  font-size: 70px;
  height: 82px;
  line-height: 100px;
  position: absolute;
  right: 0;
  text-align: center;
  width: 120px;
  color: rgba(0, 0, 0, 0.08);
}
.text-xlg {
  font-size: 2em;
}
.text-bg {
  font-size: 17px;
}
.sw_panel_body {
  padding: 2px;
  margin-top: 4px;
  overflow: hidden;
}
.progress {
  border-radius: 10px;
  margin-bottom: 5px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset;
  height: 30px;
  overflow: hidden;
}
.progress-bar {
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.15) inset;
  color: #ffffff;
  float: left;
  font-size: 13px;
  height: 100%;
  line-height: 20px;
  text-align: center;
  transition: width 0.6s ease 0s;
}
.progress_text_box {
  width: 100%;
  overflow: hidden;
}
.text_box {
  width: 50%;
  height: auto;
  overflow: hidden;
  float: left;
}

.bgcolor1 {
  background-color: #c0504d;
}
.bgcolor2 {
  background-color: #4f81bd;
}
.bgcolor3 {
  background-color: #57bfb8;
}
.bgcolor4 {
  background-color: #ac92c2;
}
.textcolor1 {
  color: #4f81bd;
}
.textcolor2 {
  color: #c0504d;
  text-align: right;
}
.textcolor3 {
  color: #ac92c2;
}
.textcolor4 {
  color: #57bfb8;
  text-align: right;
}
.sw_head_text {
  font-size: 18px;
  font-weight: 500;
}
.human_list_card {
}
.human_list {
  list-style-type: none;
  display: inline-block;
  margin: 0;
  padding: 0;
}
.human_list li {
  float: left;
  margin-right: 20px;
}
.human_list li img {
  width: 24px;
}
.per_badge {
  float: right;
  background: #777;
  padding: 15px 15px;
  font-size: 25px;
}

/*==========mylegend_area============*/
.mylegend_area {
  width: 100%;
  overflow: hidden;
  float: left;
  clear: both;
  margin-bottom: 10px;
}
.legend_group {
  width: 100%;
  background: #f0eded;
  padding-left: 5px;
  clear: both;
  height: 20px;
}
.my_legendorder {
  width: 22%;
  float: left;
  text-align: center;
  font-size: inherit;
  font-family: inherit;
  color: inherit;
}
.my_legendorder .mylegend_color {
  width: 100%;
  height: 20px;
}
.my_legendorder .mylegend_label {
  width: 100%;
}
.my_legendorder .mylegend_val {
  width: 100%;
}
@media only screen and (min-width: 480px) and (max-width: 800px) {
  .my_legendorder {
    text-align: center;
  }
}
@media (max-width: 479px) {
  .my_legendorder {
    width: 100%;
  }
}


.my_legend {
  width: 16.5%;
  float: left;
  text-align: left;
  font-size: inherit;
  font-family: inherit;
  color: inherit;
}
.my_legend .mylegend_color {
  width: 100%;
  height: 20px;
}
.my_legend .mylegend_label {
  width: 100%;
}
.my_legend .mylegend_val {
  width: 100%;
}
@media only screen and (min-width: 480px) and (max-width: 800px) {
  .my_legend {
    text-align: center;
  }
}
@media (max-width: 479px) {
  .my_legend {
    width: 100%;
  }
}

.tabulator {
  font-size: 13px !important;
}
.MuiFormLabel-root {
  font-size: 13px !important;
}
.sw_home_img {
  width: 100%;
}
.sw_home_img img {
  width: 100%;
  height: auto;
}
.sw_home_content {
  padding-top: 30px;
}
.sw_home_content p {
  text-align: justify !important;
  font-size: 22px;
  line-height: 35px;
}
.bg_white,
.bg_white:hover {
  background: #fff;
}
.login-page .card-login.card-plain .input-group.no-border .form-control {
  background-color: rgba(255, 255, 255, 0.7);
  color: #2c2c2c;
}
.login-page .card-login.card-plain .input-group.no-border .input-group-text {
  background-color: #2ca8ff;
  color: #fff;
}
.login-page .card-login.card-plain .input-group.no-border.nput-group-focus {
  background-color: #2ca8ff;
  color: #fff;
}
.sw_login_padd {
  padding: 15px 8px 15px 14px !important;
}
input.form-control.placeholder_color::placeholder {
  color: #2c2c2c !important;
  opacity: 1;
}
input.form-control.placeholder_color:-ms-input-placeholder {
  color: #2c2c2c !important;
}
input.form-control.placeholder_color::-ms-input-placeholder {
  color: #2c2c2c !important;
}
.login-page .card-login.card-plain .form-group.no-border .form-control:focus,
.login-page .card-login.card-plain .form-group.no-border .form-control:active,
.login-page .card-login.card-plain .form-group.no-border .form-control:active,
.login-page .card-login.card-plain .input-group.no-border .form-control:focus,
.login-page .card-login.card-plain .input-group.no-border .form-control:active,
.login-page .card-login.card-plain .input-group.no-border .form-control:active {
  background-color: rgba(255, 255, 255, 0.7);
  color: #2c2c2c;
}
.login-page .card-login.card-plain .form-group.no-border.input-group-focus .input-group-text,
.login-page .card-login.card-plain .input-group.no-border.input-group-focus .input-group-text {
  background-color: #2ca8ff;
  color: #fff;
}
button.btn.sw_icons {
  padding: 4px 8px;
  min-width: auto;
}
button.btn.sw_icons i {
  font-size: 20px;
}

button.MuiButton-root.sw_icons {
  padding: 8px 8px;
  min-width: auto;
}
button.MuiButton-root.sw_icons i {
  font-size: 20px;
}
.sw_card .MuiCardContent-root .MuiBox-root {
  padding: 10px 0px !important;
}
.tabulator-cell .MuiInputBase-input {
  padding: 0px 0 7px;
}
.tabulator-cell .MuiSvgIcon-root {
  font-size: 18px;
}
.tabulator-cell .MuiIconButton-root {
  padding: 0px !important;
}

.sw_makeStyles_tableContainer label + .MuiInput-formControl {
  margin-top: 12px;
}
.sw_active_patient {
  margin-top: 13px !important;
}
/*==Start Preloader==*/
.sw_relative {
  position: relative;
}
.sw_preloader_area {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  /*height: 100vh;*/
  height: 100%;
  width: 100%;
  background: rgba(555, 555, 555, 0.9);
  z-index: 9999;
}

.sw_preloader {
  width: 70px;
  height: 70px;
  margin: 0 auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
}
.lds-dual-ring {
  display: inline-block;
  width: 70px;
  height: 70px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 70px;
  height: 70px;
  margin: 1px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  border: 6px solid #3f51b5;
  border-color: #3f51b5 transparent #3f51b5 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
/*==End Preloader==*/

/*==Start Preloader global==*/
.sw_preloader_area_global {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background: rgba(555, 555, 555, 0.9);
  z-index: 9999;
}
.sw_preloader_global {
  width: 40px;
  height: 40px;
  margin: 0 auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
}
.lds-dual-ring-global {
  display: inline-block;
  width: 40px;
  height: 40px;
}
.lds-dual-ring-global:after {
  content: " ";
  display: block;
  width: 40px;
  height: 40px;
  margin: 1px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  border: 3px solid #3f51b5;
  border-color: #3f51b5 transparent #3f51b5 transparent;
  animation: lds-dual-ring-global 1.2s linear infinite;
}

@keyframes lds-dual-ring-global {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
/*==End Preloader global==*/

.sw_ToggleButtonGroup button.MuiToggleButton-root {
  padding: 6px;
}
.sw_search {
  float: left;
  width: 200px;
  margin-left: 10px;
}
.sw_search .MuiFormControl-root {
  width: 100%;
}
.sw_search .MuiInput-formControl {
  margin-top: 12px;
}
.card-signup.card select.form-control option {
  color: #000;
}
.dnone {
  display: none !important;
}

.OrderApprovalCheck {
  margin-top: 15px !important;
  margin-left: 15px !important;
}

.card-list{
  border: 1px solid #ddd;
  padding:10px !important;
}
.pt-3 {
  padding-top: 3px !important;
}
.btn-theme-color{
  color: darkslateblue;
}
.map_body{
  height: 580px;
}
.defaulterBtn{
  padding-top: 20px !important;
  padding-left: 20px !important;
}

button.EnableDisableToggle {
	border: 1px solid #ddd;
	margin-left: 5px;
	border-radius: 50%;
	padding: 1px 8px;
}
.EnableDisablePInfo {
	position: relative;
}
.EnableDisablePInfo::before {
	position: absolute;
	content: "";
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	background: rgba(242,242,242, 0.98);
	z-index: 1;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}
.btnHide {
	display: none !important;
}
.btnShow {
	display: block !important;
}
.sw_fontSize {
	font-size: 12px !important;
}

/* .MuiFormLabel-root {
	color: rgba(0, 0, 0, 1) !important;
} */
.MuiFormLabel-root {
	color: rgba(0, 0, 0, 0.6) !important;
	font-weight: 600 !important;
}
.MuiInputBase-root.Mui-disabled {
	color: rgba(0, 0, 0, 0.6) !important;
}
.MuiFormLabel-root.Mui-disabled {
	color: rgba(0, 0, 0, 0.6) !important;
}
label.MuiFormLabel-root.Mui-focused,
label.MuiFormLabel-root.MuiInputLabel-shrink.MuiFormLabel-filled {
	font-size: 16px !important;
	font-weight: 600;
	color: rgba(0,0,0,0.6) !important;
}
.HyColor {
  color:black;
}
a:hover {
  color:#000;
}
a:visited {
  color:#000;
}
.red {
	color: red;
}
.msg-error textarea.error-msg-box {
	width: 100%;
	cursor: inherit !important;
	background-color: #fff !important;
	border: 1px solid #ddd;
	padding: 10px;
	margin-top: 15px;
}

.NextPrevCard {
	width: 100%;
	overflow: hidden;
	padding: 0px 0px 12px 0px;
}

.firstProtocolName {}
.firstProtocolName h6 {
	font-size: 13px;
	font-weight: 600;
	margin-bottom: 0;
	text-transform: none;
	color: rgba(0,0,0,0.6) !important;
}
.firstProtocolName p {
	font-size: 12px;
	margin-bottom: 0;
	line-height: 16px;
}
.sw_active_patient label.MuiFormControlLabel-root {
	margin-bottom: 0;
	margin-right: 12px !important;
}
.sw_active_patient span.MuiIconButton-root {
	padding: 0px !important;
}

.sw_chosen_filter .MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input:first-child {
	padding: 8px 0;
}

.sw_relative_login {
  position: relative;
}
.sw_relative_login .sw_preloader_area {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	height: 100%;
	width: 100%;
	background: rgba(555, 555, 555, 0.7);
	z-index: 9999;
}
.sw_relative_login .sw_preloader {
	width: 70px;
	height: 70px;
	margin: 0 auto;
	position: absolute;
	top: 50%;
	left: 0;
	right: 0;
	z-index: 9999;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
}
.sw_tld .MuiToggleButton-root {
	text-transform: capitalize !important;
	text-align: left !important;
}
.sw_tld .MuiToggleButton-label {
	justify-content: start !important;
	text-align: left !important;
}
.sw_tld .MuiToggleButton-root:hover,
.sw_tld .MuiToggleButton-root.Mui-selected {
	color: #fff !important;
	background-color: #2ca8ff !important;
}
.sw_tld .MuiToggleButton-root {
	color: #111;
	border: 1px solid #2ca8ff !important;
}

.checkBoxCustom {
  margin-top: 15px !important;
  margin-left: 5px !important;
}

.sw_ToggleButtonGroup .MuiToggleButton-root:hover,
.sw_ToggleButtonGroup .MuiToggleButton-root.Mui-selected {
	color: #fff !important;
	background-color: #2ca8ff !important;
}
.sw_ToggleButtonGroup .MuiToggleButton-root {
	color: #111;
	border: 1px solid #2ca8ff !important;
}
.logoFormate{
  display: flex;
}
.logoFormate>*:first-child {
  padding-right: 10px;
}




/*=====Permission====*/
.permission_card {
	padding: 150px;
	text-align: center;
	display: inline-block;
	width: 100%;
	color: #fff;
}
.permission_card h1 {
	font-size: 30px;
	font-weight: bold;
	width: 100%;
	display: inline-block;
}
.permission_card .goto_btn {
	background: #fff;
	color: #111;
	padding: 12px 40px;
	margin-top: 15px;
	display: inline-block;
	border-radius: 3px;
}
.permission_card .goto_btn:hover {
	text-decoration: none;
	background: #1c9ff7;
}

.map_body {
  height: 612px; 
}

.bgPrimary {
	background: #0055a2 !important;
}

/*==============*/
.sw_dataname {
	width: 100%;
	border-bottom: 1px solid #ddd;
	overflow: hidden;
	padding-bottom: 10px;
	margin-bottom: 10px;
}
.sw_dataname strong {
	margin-right: 5px;
	float: left;
}
.sw_dataname span {
	float: left;
}
.sw_textare_control {
	width: 100%;
	margin: 10px 0;
}
.sw_textare_control label {
	font-weight: 700;
}
.sw_textarea {
	width: 100%;
	height: 200px;
}
.sw_columns_control {
	width: 100%;
	margin-top: 20px;
	margin-bottom: 10px;
}
.sw_columns_control label {
	font-weight: 700;
}
.sw_columnslist {
	width: 100%;
}

.dashboard_block {
 padding: 46px 0px 46px 0px !important;
}
.facility_dashboard_block {
  height: 140px;
}
.facilitydashboard_sBlock {
  height: 450px;
}
.facilitydashboard_tBlock {
  height: 300px;

}
.text-labelval {
 height: 22px;

}
.text-label {
  line-height: 1.5;
  display: inline-block;
  vertical-align: middle;
  /* display: block; 
  margin-top: 18px;  */

}
.balance_cardbg{
  background-color: #0055a2 !important;
  color: white !important;
}

.MuiButton-root.active:hover,
.MuiButton-root.active {
	background-color: #303f9f;
	color: #fff;
}
.FontStyle{
	margin-top: 5px;
	margin-bottom: 0px;
	font-size: 15px;
	font-weight: bold;
}
.checkBoxCustomdynamic {
  margin-left: 5px !important;
}
.MuiButton-root.pick_btn_place {
	position: absolute !important;
	padding: 1px 3px 6px 3px;
	min-width: 33px;
	right: 17px;
}
.pick_btn_place .MuiButton-label {
	padding: 0px !important;
}
.add_plus {
	margin: 21px 2px 2px 4px;
	float: left;
}
.page-header {
	color: #111;
	/* background: #f5f5f5; */
}
.page-header .content-center {
	color: #111;
}
.sw_inner_card {
	color: #000;
	padding: 10px;
	background: #f6f5f5;
	margin-top: 60px;
	border-radius: 10px;
	border: 1px solid #dbd9d9;	
}
.sw_inner_card .justify-product.mb-2 {
	margin-bottom: 5px !important;
}
.sw_inner_card .justify-product .MuiGrid-item {
	line-height: 1 !important;
}
.sw_makeStyles_tableContainer {
	color: #000;
	padding: 10px;
	border-radius: 10px;
	background: #f6f5f5;
	border: 1px solid #dbd9d9;
}
.sw_makeStyles_tableContainer .MuiPaper-elevation1 {
	box-shadow: none;
	border: 1px solid #e6e6e6;
}
.sw_topmargin {
	margin-top: 60px;
}
.tabulator .tabulator-header .tabulator-col .tabulator-col-content .tabulator-col-title {
	font-family: var(--secondary-font-family);
}
.tabulator-row .tabulator-cell {
	font-family: var(--primary-font-family);
}
.sw_topmargin .MuiGrid-spacing-xs-3 > .MuiGrid-item {
	padding: 10px 12px;
}
.index-page .navbar.navbar-transparent,
.login-page .navbar.navbar-transparent {
	padding-top: 5px !important;
}
.index-page .logo_item,
.login-page .logo_item {
	padding-top: 10px;
}
.footer ul li a {
	text-transform: none !important;
}

.sw_inner_container {
	min-height: 753px;
	background-size: cover;
	background-position: top center;
	background: #ffffff;
}
.sw_star {
	font-size: 13px !important;
	margin-left: 2px !important;
}
label.MuiFormLabel-root.Mui-focused .sw_star, 
label.MuiFormLabel-root.MuiInputLabel-shrink.MuiFormLabel-filled .sw_star{
	font-size: 16px !important;
	margin-left: 2px !important;
}

.material-border {
  padding: 8px !important;
  border: 1px solid #bcbaba;
  margin: 5px !important;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.headingPara{
  background-color: #f8f8f8;
  text-align: center;
  padding: 10px;
  font-size: 22px;
  border-top: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-left: 1px solid #ccc;
}
.titleLavel{
  font-size: 11px;
  font-weight: 500;
  font-family: var(--secondary-font-family);
}
.footerBorderRight{
  border-right: 1px solid #ccc;
}
.footersectionText {
  background-color: #f4f4f4 !important;
  padding: 20px !important;
  text-align: center !important;
  font-size: 14px !important;
  color: #333 !important;
  margin: 0 auto;
}

.footersectionText p {
  margin: 0; /* Remove default margin for the paragraph */
}

.tabulator-row.tabulator-group span{
  color: green!important;
}

.statusCompleted{

  margin-right: 7px;
  width: 12px !important;
  height: 12px !important;
  background: #cff09e;
  display: inline-block;
  border: 3px solid #507c5c;
  border-radius: 100%;

  /* height: 8px;
  width: 8px;
  background-color: rgb(0, 128, 0);
  border-radius: 50%;
  display: inline-block; */

}

.Map_Style{
  overflow: hidden !important;
  width: 100% !important;
  height: 620px !important;
}

.statusDraft{

  margin-right: 7px;
  width: 12px !important;
  height: 12px !important;
  background: #f4b2b0;
  display: inline-block;
  border: 3px solid #b3404a;
  border-radius: 100%;

  /* height: 8px;
  width: 8px;
  background-color: rgb(255, 0, 0);
  border-radius: 50%;
  display: inline-block; */

}
.statusNotApproved{

  margin-right: 7px;
  width: 12px !important;
  height: 12px !important;
  background: #FFFF00;
  display: inline-block;
  border: 3px solid #FCBE11;
  border-radius: 100%;
  /* height: 8px;
  width: 8px;
  background-color: rgb(255, 165, 0);
  border-radius: 50%;
  display: inline-block; */

}



.modalPopupPost .swal-button--danger {
  background-color: #3f51b5 !important;
}

.modalPopupDelete .swal-button--danger {
  background-color: #e64942 !important;
}

.icon-container i {
	margin: 5px;
  }

  .redtextcolor {
    color: rgba(255, 0, 0, 0.957);
	font-weight: 700; 
  }
  .masterredtextcolor {
    color: rgba(255, 0, 0, 0.957);
	font-weight: 700; 
  }
  .greenColor{
	color:green;
	font-weight: bold;
  }
  .redColor{
	color:red;
	font-weight: bold;
  }

  .dhx_container_inner {
    height: 500px;
  }
  .dhx_settings-button-wrap--readonly{
    display: none !important;
  }
  
  .dhx_sample-controls1 {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    min-height: 33px;
    height: 35px;
      border-bottom: 1px solid #DFDFDF;
      font-family: Roboto,Arial,Tahoma,Verdana,sans-serif;
      font-size: 14px;
  }
 
.SignUpDiv {
	padding: 85px 0px 0px 0px !important;
}
.SignUpDiv .MuiBox-root > :not(style) {
	width: 100%;
}
.SignUpDiv .sw_TextFieldGroup {
	position: relative;
}
.SignUpDiv .sw_TextFieldGroup .MuiFormControl-root {
	width: 100%;
	margin-bottom: 14px;
}
.SignUpDiv .sw_TextFieldGroup .MuiOutlinedInput-root {
	background: #fff;
	border-radius: 0px 50px 50px 0px;
}
.SignUpDiv .sw_TextFieldGroup .MuiOutlinedInput-notchedOutline {
	border-color: #fff;
}
.SignUpDiv .sw_TextFieldGroup .sw_TextFieldIcon {
	position: absolute;
	left: -48px;
	top: 0;
	width: 52px;
	height: 44px;
	background: #2ca8ff;
	line-height: 44px;
	text-align: center;
	color: #fff;
	border-radius: 50px 0px 0px 50px;
}


.SignUpDiv .sw_TextFieldGroup .sw_TextFieldRightIcon {
	position: absolute;
	right: -3px;
	top: 0;
	width: 52px;
	height: 45px;
	background: #2ca8ff;
	line-height: 45px;
	text-align: center;
	color: #fff;
	border-radius: 0px 50px 50px 0px;
}

.SignUpDiv .sw_TextFieldGroup .sw_TextFieldRightIcon2 {
	position: absolute;
	right: -3px;
	top: 0;
	width: 52px;
	height: 45px;
	background: #2ca8ff;
	line-height: 45px;
	text-align: center;
	color: #fff;
	border-radius: 0px 50px 50px 0px;
}


.SignUpDiv .sw_TextFieldGroup .MuiOutlinedInput-input {
  padding: 14.5px 14px;
  height: 1.1776em;
}

.SignUpDiv .text-center {
  margin-top: -15px;
}

.SignUpDiv .text-center-view {
  margin-top: 4px;
  text-align: center;
}

.SignUpDiv .sw_TextFieldGroup .MuiInputLabel-formControl{
  top:5px;
}

.SignUpDiv .Mui-focused {
 padding-top: 0px !important;
}


