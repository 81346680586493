/* ======================
   Menu css
   ====================== */
.logo {
	width: auto;
	height: auto;
	overflow: hidden;
	float: left;
	text-align: left;
}
.logo a {
	font-size: 24px;
	font-weight: 600;
	color: #111;
	text-decoration: none;
	line-height: 1.625rem;
	font-family: var(--secondary-font-family);
}
.sw_sub_title {
	width: 100%;
	float: left;
	color: #111;
	font-family: var(--secondary-font-family);
}
.header-menu {
	height: auto;
	width: 100%;
	padding: 4px 0px;
	background-color: #ffffff;
	position: fixed;
	z-index: 999;
	top: 0;
	left: 0;
	right: 0;
	-webkit-transition: all 0.3s ease-out 0s;
	-moz-transition: all 0.3s ease-out 0s;
	-ms-transition: all 0.3s ease-out 0s;
	-o-transition: all 0.3s ease-out 0s;
	transition: all 0.3s ease-out 0s;
	border-bottom: 1px solid #ccc;
}
.sticky {
  background-color: #2ca8ff !important;
  border-bottom: none;
}
.tp-mega-full {
  position: relative;
  width: 100%;
  line-height: 1;
  margin-left: 15px;
}
.tp-menu {
  display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
}
.tp-menu nav {
  width: 100%;
  float: left;
  padding-top: 8px;
}
.tp-menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
  float: left;
  
}
.tp-menu ul li {
  float: left;
  position: relative;
  
}
.tp-menu ul li a {
  color: #111;
  display: block;
  padding: 13px 10px;
  font-size: 13px;
  text-decoration: none;
  text-transform: uppercase;
  font-family: var(--primary-font-family);
}
.tp-menu ul li:hover > a {
  background: rgba(255, 255, 255, 0.2);
}

/*submenu*/
.tp-menu ul li ul.submenu {
  position: absolute;
  top: 110%;
  /* left: 0; */
  width: 220px;
  border: 1px solid #eee;
  background: #fff;
  visibility: hidden;
  opacity: 0;
  z-index: 99999999;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -ms-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.tp-menu ul li ul.submenu.left_position {
  left: 0 !important;
}
.tp-menu ul li ul.submenu.right_position {
  right: 0 !important;
}
.tp-menu ul li:hover ul.submenu {
  visibility: visible;
  opacity: 1;
  top: 100%;
}
.tp-menu ul li ul.submenu li {
  float: none;
  border-bottom: 1px solid #eee;
  margin: 0;
}
.tp-menu ul li ul.submenu li:last-child {
  border-bottom: none;
}
.tp-menu ul li ul.submenu li a {
  padding: 6px 15px;
  color: #222;
  text-transform: capitalize;
  line-height: 1.5;
  font-family: var(--primary-font-family);
}
span.tp-angle {
  margin-left: 3px;
  font-size: 8px;
  margin-top: 3px;
}

/*three level menu*/
.tp-menu ul li ul.submenu li ul.submenu {
  /* left: 100%; */
  top: 0;
  opacity: 0;
  visibility: hidden;
}
.tp-menu ul li ul.submenu li ul.submenu.left_position {
  left: 100% !important;
}
.tp-menu ul li ul.submenu li ul.submenu.right_position {
  right: 100% !important;
}

.tp-menu ul li ul.submenu li:hover > ul.submenu {
  opacity: 1;
  visibility: visible;
}

/*mega-menu*/
.tp-menu ul li .mega-menu {
  position: absolute;
  top: 110%;
  left: 0;
  width: 500px;
  background: #fff;
  border: 1px solid #eee;
  padding: 20px 0px;
  z-index: 999999;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -ms-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.tp-menu ul li:hover .mega-menu {
  visibility: visible;
  opacity: 1;
  top: 100%;
}
.tp-menu ul li .mega-menu ul {
  float: left;
}
.tp-menu ul li .mega-menu ul li {
  float: none;
  margin-right: 0;
}
.tp-menu ul li .mega-menu ul li a {
  padding: 10px 15px;
  text-transform: capitalize;
  color: #222;
  font-family: var(--primary-font-family);
}
.tp-menu ul li ul.submenu li:hover > a,
.tp-menu ul li .mega-menu ul li:hover a {
  color: #2ca8ff;
  background: transparent;
  font-family: var(--primary-font-family);
}
.tp-menu ul li .mega-menu ul li.mega-title {
  padding: 10px 15px;
  color: #222;
  font-weight: 500;
}
.tp-menu ul li .mega-menu ul.megafixed-col-1 {
  width: 100%;
}
.tp-menu ul li .mega-menu ul.megafixed-col-2 {
  width: 50%;
}
.tp-menu ul li .mega-menu ul.megafixed-col-3 {
  width: 33.33333333333333%;
}
.tp-menu ul li .mega-menu ul.megafixed-col-4 {
  width: 25%;
}
.tp-menu ul li .mega-menu ul.megafixed-col-5 {
  width: 20%;
}
.tp-menu ul li .mega-menu ul.megafixed-col-6 {
  width: 16.66666666666667%;
}

/*mega-menu full*/
.tp-menu ul li.tp-static {
  position: static;
}
.tp-menu ul li .mega-menu.mega-full {
  width: 100%;
}
.tp-menu ul li .mega-menu ul {
  border-right: 1px solid #eee;
  padding: 0px 10px;
}
.tp-menu ul li .mega-menu ul:last-child {
  border-right: none;
}
.tp-menu ul li .mega-menu ul li.mega-col-image {
  overflow: hidden;
  padding: 0px 15px;
}
.tp-menu ul li .mega-menu ul li.mega-col-image a {
  padding: 0;
}
.tp-menu ul li .mega-menu ul li.mega-col-image img {
  width: 100%;
  height: auto;
  border: 1px solid #eee;
  border-radius: 5px;
}

.tp-menu ul li .mega-menu.mega-full ul.mega-col-1 {
  width: 100%;
}
.tp-menu ul li .mega-menu.mega-full ul.mega-col-2 {
  width: 50%;
}
.tp-menu ul li .mega-menu.mega-full ul.mega-col-3 {
  width: 33.33333333333333%;
}
.tp-menu ul li .mega-menu.mega-full ul.mega-col-4 {
  width: 25%;
}
.tp-menu ul li .mega-menu.mega-full ul.mega-col-5 {
  width: 20%;
}
.tp-menu ul li .mega-menu.mega-full ul.mega-col-6 {
  width: 16.66666666666667%;
}

.desktop_menu {
  display: block;
}

/*mobile menu*/
button.mobile_menu_bars_icon {
  background: none;
  border: none;
  color: #111;
  font-size: 22px;
  display: none;
  float: left;
  margin-left: 10px;
}
.header-menu.sticky button.mobile_menu_bars_icon {
  color: #fff;
}

button.mobile_menu_bars_icon i {
}
.mobile_menu_close {
  width: 100%;
  overflow: hidden;
  padding: 5px 20px;
}
.mobile_menu_close button.mobile_menu_close_icon {
  background: none;
  border: none;
  color: #fff;
  font-size: 22px;
  float: right;
}
button.mobile_menu_bars_icon:focus,
.mobile_menu_close button.mobile_menu_close_icon:focus {
  outline: none;
  outline: none;
}

.mobile-menu-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 999999;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}
.mobile-menu-wrapper.open {
  opacity: 1;
  visibility: visible;
}
.mobile-menu-wrapper .off-canvas-overlay {
  background-color: rgba(0, 0, 0, 0.4);
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}
.mobile-menu-wrapper .offcanvas-body {
  background-color: #2ca8ff;
  width: 300px;
  height: 100%;
  padding: 0px 0px 100px 0px;
  position: relative;
  float: right;
  overflow: scroll;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}
.sw_mobile_menu {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.sw_mobile_menu li {
}
.sw_mobile_menu li a {
  font-size: 13px;
  color: #fff;
  text-transform: uppercase;
  line-height: 18px;
  position: relative;
  display: inline-block;
  padding: 7px 10px 7px 20px;
  width: 100%;
}
.sw_mobile_menu li:hover > a:hover {
  color: #fff;
  text-decoration: none;
  background-color: rgba(255, 255, 255, 0.2);
}

.sw_mobile_menu li ul.submenu {
  list-style-type: none;
  padding: 0px 0px 0px 15px;
  margin: 0;
  visibility: hidden;
  opacity: 0;
  display: none;
}
.sw_mobile_menu li:hover ul.submenu {
  visibility: visible;
  opacity: 1;
  display: block;
}
.sw_mobile_menu li ul.submenu li {
}
.sw_mobile_menu li ul.submenu li a {
  text-transform: capitalize;
}

/*three level menu*/
.sw_mobile_menu li ul.submenu li ul.submenu {
  opacity: 0;
  visibility: hidden;
  display: none;
}
.sw_mobile_menu li ul.submenu li:hover > ul.submenu {
  opacity: 1;
  visibility: visible;
  display: block;
}
.sw_mobile_menu li ul.submenu li ul.submenu li {
}
.sw_mobile_menu li ul.submenu li ul.submenu li a {
  text-transform: capitalize;
}

/* ======================
   Responsive css
   ====================== */
@media (min-width: 768px) and (max-width: 991px) {
  .desktop_menu {
    display: none;
  }
  button.mobile_menu_bars_icon {
    display: block;
  }
}
@media (max-width: 767px) {
  .desktop_menu {
    display: none;
  }
  button.mobile_menu_bars_icon {
    display: block;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .desktop_menu {
    display: none;
  }
  button.mobile_menu_bars_icon {
    display: block;
  }
}